// Import Variables
@import "../../assets/scss/variables";

.upload-file {
  .p-fileupload-buttonbar {
    .p-button {
      width: initial !important;
      background-color: $main !important;
      border-color: $main !important;
    }
  }

  .thumb-image {
    width: 100px;
    height: 66px;
    object-fit: cover;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #f0f0f0;
  }

  .p-filter-column {
    .p-inputtext {
      width: 100%;
    }
  }
  .p-confirm-dialog-accept {
    background-color: $main !important;
  }

  .p-confirm-dialog-reject {
    span {
      color: $main;
    }
  }
  a {
    color: $main;
  }
}