// Colors
$main: #1FA78F;
$white: #fff;
$gray: #646F87;
$dark-gray: #293651;
$white-gray: #8D99AE;
$light-gray: #D3DCEE;

// Fonts
$body: 200 16px 'Gilroy';
$title: 400 24px 'Gilroy';
$title-small: 300 20px 'Gilroy';
$title-med: 300 24px 'Gilroy';
$title-big: 400 24px 'Gilroy';
$big: 400 48px 'Gilroy';