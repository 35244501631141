// Import Variables
@import "../../../assets/scss/variables";


header {
  background-color: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.07);

  .p-menubar {
    background: unset;
    border: unset;

    .p-button {
      padding: 14px !important;
      background-color: $main !important;
      border-color: $main !important;
    }
  }
}