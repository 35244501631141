// Import Variables
@import "../../assets/scss/variables";

.login {
  margin-bottom: -84px;

  .full-height {
    height: 100vh;
  }

  .logo-wrapper {
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 250px;
    }

    .title {
      font-size: 22px;
      font-weight: 400;
      margin-top: 20px;
      color: $gray;
    }
  }

  .p-password,
  input {
    width: 100%;
  }

  input {
    padding: 14px !important;
  }

  .p-button {
    width: 100%;
    padding: 14px !important;
    background-color: $main !important;
    border-color: $main !important;
  }
}

@media (max-width: 500px) {
  .login {
    .logo-wrapper {
      display: block;
      text-align: center;
      margin-bottom: 40px;

      .title {
        margin-top: -10px;
      }
    }
  }
}
