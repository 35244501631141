// Import Variables
@import "../../assets/scss/variables";

.add-service {
  .p-button-outlined {
    color: $main !important;
    border-color: $main !important;
  }

  .preview {
    max-height: 200px;
    display: block;
    width: auto;
    max-width: 100%;
    margin-bottom: 10px;
  }
}