// Import Variables
@import "../../assets/scss/variables";

.info {
  .info-form {
    .p-button-outlined {
      color: $main !important;
      border-color: $main !important;
    }

    .p-button.main-color {
      background-color: $main !important;
      border-color: $main !important;
    }
  }

  .preview {
    height: 100px;
    display: block;
    width: auto;
    margin-bottom: 10px;
    background-color: #e6e6e6;
  }
}

.upload-file-modal {
  .p-dialog-footer {
    .p-button {
      background-color: $main !important;
      border-color: $main !important;
    }
  }
}
