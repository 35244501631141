// Import Variables
@import "../../../assets/scss/variables";

footer {
  padding: 15px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.07);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray;

  img {
    margin-left: 5px;
  }
}