// Import Variables
@import "./assets/scss/variables";

body {
  margin: 0;
  font-family: "Gilroy", serif !important;
  font-weight: 200 !important;
  background: url("./assets/img/pattern.svg") #f3f3f3;
  position: relative;
  min-height: 100vh;
  padding-bottom: 84px;
}

img {
  max-width: 100%;
}

// Fonts
@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy-Bold.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy-SemiBold.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy-Medium.ttf);
  font-weight: 200;
}

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy-Light.ttf);
  font-weight: 100;
}

// Container
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

// Global Styling
input,
textarea {
  &:hover {
    border-color: $main !important;
  }
}
.p-component {
  font-family: "Gilroy", serif !important;
  font-weight: 200 !important;
}

.w-100 {
  width: 100% !important;

  input {
    width: 100%;
  }
}

.p-dropdown {
  .p-dropdown-label {
    &.p-inputtext,
    &.p-placeholder {
      font-weight: 300 !important;
      font-family: "Gilroy", serif !important;
    }
  }

  &.p-dropdown-clearable {
    .p-dropdown-label {
      font-weight: 300;
      font-family: "Gilroy", serif !important;
    }
  }
}

.p-confirm-dialog-accept,
.primary-color {
  background-color: $main !important;
  border-color: $main !important;
}

.p-confirm-dialog-reject {
  span {
    color: $main;
  }
}

.p-password input {
  width: 100%;
}

.p-filter-column {
  input {
    width: 100%;
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    &.p-highlight {
      background-color: $main !important;
      border-color: $main !important;
    }
  }
}

.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      background-color: $main !important;
      border-color: $main !important;
    }

    &:hover {
      border-color: $main !important;
    }
  }
}

.p-fileupload-content {
  .p-button {
    background: $main;
  }
}

.p-progressbar {
  .p-progressbar-value {
    background: $main !important;
  }
}

// DataTable Responsive
.table-header {
  display: flex;
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 768px) {
  .table-header {
    display: block !important;

    .p-input-icon-left {
      margin-top: 15px;
      width: 100%;
      display: block;

      input {
        width: 100%
      }
    }
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border-top: 0;
    border-bottom: 0;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4em 1em -.4em -.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}