// Import Variables
@import "../../assets/scss/variables";

.portfolio {
  .thumb-image {
    width: 100px;
    height: 66px;
    object-fit: cover;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.add-project {
  .p-button-outlined {
    color: $main !important;
    border-color: $main !important;
  }

  .preview {
    max-height: 200px;
    display: block;
    width: auto;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .items {
    margin-bottom: 10px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: #eeeeee;
      border-radius: 10px;
      margin-bottom: 10px;
      a {
        width: 75%;
        word-break: break-all;
      }
    }
  }
}
