// Import Variables
@import "../../assets/scss/variables";

.home {
  .counter {
    text-align: center;
    color: #fff;
    padding: 30px;
    border-radius: 5px;
    font-size: 24px;
    background-color: $gray;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 70px;
      height: 70px;
      display: flex;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.19);
      margin-right: 15px;
    }

    &.green {
      background-color: $main;
    }

    &.light {
      background-color: $white-gray;
    }
  }
}

@media (max-width: 768px) {

}
