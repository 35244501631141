// Import Variables
@import "../../assets/scss/variables";

.not-found {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;

  span {
    font-size: 100px;
    font-weight: 300;
  }

  h3 {
    font-weight: 200;
    color: $main;
  }
}