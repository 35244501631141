// Import Variables
@import "../../assets/scss/variables";

.blog {
  .thumb-image {
    width: 100px;
    height: 66px;
    object-fit: cover;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.add-article {
  .p-button-outlined {
    color: $main !important;
    border-color: $main !important;
  }

  .preview {
    max-height: 200px;
    display: block;
    width: auto;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

.comments {
  ul {
    list-style: none;
    padding: 0;
    li {
      .comment {
        display: flex;
        margin-top: 30px;
        align-items: flex-start;
        padding: 30px;
        border-radius: 10px;
        background-color: #f9f9f9;
        position: relative;

        h1 {
          margin-bottom: 12px;
        }

        p {
          line-height: 22px;
        }

        img {
          width: 100px;
          border-radius: 50%;
          background-color: $main;
          padding: 5px;
          margin-top: 20px;
        }

        .content {
          margin: 0 30px;
          width: 100%;

          span {
            a {
              color: $main;
              margin-left: 8px;
            }
          }
        }

        button.delete {
          position: absolute;
          top: 30px;
          right: 30px;
        }
      }

      ol {
        list-style: none;
      }
    }
  }
}
